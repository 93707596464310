{
  "name": "patient",
  "version": "2.26.1",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --port=5200 --host 0.0.0.0 --disable-host-check --open",
    "build": "ng build --configuration=production --aot=true --optimization=true",
    "build:stag": "ng build --configuration=stag --aot=true --optimization=true",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "update-angular": "ng update @angular/core @angular/cli @angular/cdk @angular-devkit/build-angular @angular/compiler-cli @angular/material @angular/material-experimental",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org mar-saude --project patient ./dist/patient && sentry-cli sourcemaps upload --org mar-saude --project patient ./dist/patient",
    "changelog-version": "node generate-changelog-update-version.js",
    "commit-version": "git add . && git commit -m'Version name: '$(grep -m1 version package.json | awk -F: '{ print $2 }' | sed 's/[\", ]//g')''",
    "release": "npm run changelog-version && npm run commit-version && git push && git checkout main && git pull  && git merge --no-ff dev && git push && git checkout -",
    "prepare": "is-ci || husky install"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.11",
    "@angular/cdk": "^18.2.13",
    "@angular/common": "^18.2.11",
    "@angular/compiler": "^18.2.11",
    "@angular/core": "^18.2.11",
    "@angular/forms": "^18.2.11",
    "@angular/material": "^18.2.13",
    "@angular/material-experimental": "^18.2.13",
    "@angular/platform-browser": "^18.2.11",
    "@angular/platform-browser-dynamic": "^18.2.11",
    "@angular/router": "^18.2.11",
    "@ngx-formly/core": "^6.1.8",
    "@ngx-formly/material": "^6.1.8",
    "@sentry/angular": "^8.13.0",
    "@sentry/cli": "^2.32.1",
    "@typeform/embed": "4.10.0",
    "@types/numeral": "^2.0.5",
    "@types/pdfjs-dist": "^2.10.378",
    "browserslist": "^4.24.4",
    "dayjs": "^1.11.8",
    "iframe-resizer": "4.3.11",
    "libheif-js": "^1.18.2",
    "marked": "^15.0.6",
    "ng2-currency-mask": "^13.0.3",
    "ngx-file-drop": "^16.0.0",
    "ngx-flexible-layout": "^18.0.1",
    "ngx-mask": "^16.2.0",
    "numeral": "^2.0.6",
    "pdfjs-dist": "^4.7.76",
    "rxjs": "~7.8.0",
    "swiper": "^11.0.7",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.11",
    "@angular/cli": "~18.2.11",
    "@angular/compiler-cli": "^18.2.11",
    "@types/jasmine": "~5.1.4",
    "husky": "^9.0.11",
    "jasmine-core": "~4.6.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "semver": "^7.6.3",
    "typescript": "~5.4.5"
  }
}